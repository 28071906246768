.privacyPolicyContainer {
  max-width: 1110px;
  width: 95%;
  margin: auto;
  margin-top: 150px;
  font-family: 'DM Sans';
  font-size: 18px;
}

.privacyPolicyContainer > h1 ,
.privacyPolicyContainer > h2 {
  font-family: 'Recoleta';
}
