.navbarContainer {
	position: fixed;
	top: 16px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 10000;
	width: 100vw;
	max-height: 70px;
}

.navBasket {
	padding: 8px 8px 8px 24px;
	width: 95vw;
	box-sizing: border-box;
	margin: auto;
	max-width: 1196px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: #f2f2f2;
	box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.04);
	border-radius: 64px;
}

.navBtn {
	background: #2a7560;
	border-radius: 56px;
	padding: 12px 40px;
	font-family: 'DM Sans';
	font-weight: 500;
	font-size: 24px;
	line-height: 31px;
	color: #ffffff;
	text-align: center;
	cursor: pointer;
}

@media screen and (max-width: 500px) {
	.nav-logo {
		max-width: 150px;
	}
	.navBtn {
		font-size: 14px;
		line-height: 18px;
		padding: 8px 20px;
	}
}
