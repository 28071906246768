@import url(https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&family=Vollkorn:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap);

@font-face {
	font-family: 'Recoleta Alt';
	src: url("/static/media/Recoleta Alt Bold.40523479.otf") format('opentype');
}

@font-face {
	font-family: 'Recoleta';
	src: url(/static/media/Recoleta-RegularDEMO.fd9bfd35.otf) format('opentype');
}

@font-face {
	font-family: 'Motherva';
	src: url(/static/media/Al_Motherva_Normal.521367a9.otf) format('opentype');
	font-weight: normal;
}

@font-face {
	font-family: 'Motherva';
	src: url(/static/media/Al_Motherva_Light.06dfdfcc.otf) format('opentype');
	font-weight: 300;
}

@font-face {
	font-family: 'Motherva';
	src: url(/static/media/Al_Motherva_Bold.9fb3b373.otf) format('opentype');
	font-weight: bold;
}

@font-face {
	font-family: 'Motherva';
	src: url(/static/media/Al_Motherva_Normal.521367a9.otf) format('opentype');
	font-weight: normal;
}

@font-face {
	font-family: 'Victor-Serif';
	src: url(/static/media/VictorSerif-Regular.42e44c3c.otf) format('opentype');
}

@font-face {
	font-family: 'Victor-Serif';
	src: url(/static/media/VictorSerif-RegularItalic.18dcdf63.otf) format('opentype');
	font-style: italic;
}

* {
	margin: 0;
	padding: 0;
	-webkit-font-smoothing: antialiased;
	-webkit-font-feature-settings: none;
	        font-feature-settings: none;
	-webkit-font-variant-ligatures: none;
	        font-variant-ligatures: none;
}

body,
html {
	background: #fff;
	scroll-behavior: smooth;
	-webkit-font-smoothing: antialiased;
	overscroll-behavior-y: none;
}

body::-webkit-scrollbar {
	display: none;
}
body::-webkit-scrollbar-thumb {
	display: none;
}

.callIconFloatingBtn {
	padding: 15px;
	box-sizing: border-box;
	z-index: 10000;
	border-radius: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	grid-gap: 5px;
	gap: 5px;
	box-shadow: 0px 4px 44px rgb(0 0 0 / 16%);
	text-decoration: none;
	color: #f8f5f0;
}

.callIconFloatingBtn2 {
	padding: 15px;
	box-sizing: border-box;
	z-index: 10000;
	border-radius: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	grid-gap: 5px;
	gap: 5px;
	box-shadow: 0px 4px 44px rgb(0 0 0 / 16%);
	text-decoration: none;
	color: #f8f5f0;
	cursor: pointer;
}

.floatingActionBtn {
	position: fixed;
	bottom: 5vh;
	right: 5vh;
	display: inline-flex;
	grid-gap: 15px;
	gap: 15px;
	flex-direction: row-reverse;
	z-index: 10000;
}

.callIconFloatingBtn > img {
	width: 20px;
	height: 20px;
}

@media screen and (max-width: 960px) {
	html,
	body {
		overflow-x: unset;
		height: -webkit-fit-content !important;
		height: -moz-fit-content !important;
		height: fit-content !important;
	}
	#blogCircle {
		display: none;
	}
	.testimonialCircle {
		display: none;
	}
}

.modal-wrapper {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 10001;
	height: 100vh;
}

.modal-backdrop {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 10001;
	background-color: rgba(0, 0, 0, 0.7);
	height: 100vh;
}

.modal-box {
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	overflow-y: auto;
	background-color: white;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
	z-index: 10002;
}

.formContainer {
	background: #f2f2f2;
	border-radius: 8px;
	padding: 24px;
	box-sizing: border-box;
	width: 90vw;
	max-width: 450px;
	overflow: auto;
	max-height: 100vh;
}

input::-webkit-input-placeholder {
	font-family: 'DM Sans';
	font-size: 20px;
	line-height: 26px;
	color: #bdbdbd;
}

input:-ms-input-placeholder {
	font-family: 'DM Sans';
	font-size: 20px;
	line-height: 26px;
	color: #bdbdbd;
}

input::placeholder {
	font-family: 'DM Sans';
	font-size: 20px;
	line-height: 26px;
	color: #bdbdbd;
}

input::-ms-input-placeholder {
	font-family: 'DM Sans';
	font-size: 20px;
	line-height: 26px;
	color: #bdbdbd;
}

input,
select {
	-webkit-appearance: none;
	appearance: none;
	padding: 16px 20px;
	box-sizing: border-box;
	background: #ffffff;
	border: 1px solid #e0e0e0;
	box-sizing: border-box;
	border-radius: 8px;
	font-family: 'DM Sans';
	font-size: 20px;
	line-height: 26px;
	width: 100%;
	max-width: 450px;
	margin: 8px auto;
}

select {
	background: #fff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACFSURBVHgB7ZNRDoAgCIaRk9RRmud2HCVP4opmrQwn+Mz3YjH4P3UTwHGc8P5JKe28IuJ2ksEAES2lFOLvGON611HovRp5AOzhv5mPgHd+LtkiacJzzXgImoHedWl6w+ygdiOiYBRgOWVX0AviujZ8KJAktawKVwkECWjD1YJGMvUQHWeeA877hjOx5P7hAAAAAElFTkSuQmCC) no-repeat center right;
	background-position-x: 97%;
	color: #000;
}

input:focus,
select:focus {
	outline: none;
}

select:invalid {
	color: #bdbdbd;
}

.firstOption {
	font-family: 'DM Sans';
	font-size: 20px;
	line-height: 26px;
	color: #bdbdbd;
}

option {
	font-family: 'DM Sans';
	font-size: 20px;
	line-height: 26px;
	color: #000;
}

.btnSubmit {
	background: #2a7560;
	margin-top: 16px;
	font-family: 'DM Sans';
	font-weight: 500;
	font-size: 20px;
	line-height: 26px;
	color: #ffffff;
	cursor: pointer;
}

.FormHeading {
	font-family: 'DM Sans';
	font-weight: bold;
	font-size: 20px;
	line-height: 26px;
	color: #828282;
	margin-bottom: 16px;
}

.closeModal {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 21px;
	-webkit-text-decoration-line: underline;
	        text-decoration-line: underline;
	color: #bdbdbd;
	text-align: center;
	cursor: pointer;
	margin-top: 8px;
}

.footerContainer {
	background: linear-gradient(
			180deg,
			rgba(0, 0, 0, 0) 0%,
			rgba(0, 0, 0, 0.2) 100%
		),
		#3b7fb1;
	padding: 6em 2em;
	-webkit-transform: translateY(100%);
	        transform: translateY(100%);
}

.animated {
	transition: all 1.2s ease-in-out;
	-webkit-transform: translateY(0);
	        transform: translateY(0);
}

.footerContent {
	display: inline-flex;
	grid-gap: 50px;
	gap: 50px;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
}

.footerHeading {
	font-family: 'Recoleta-Alt';
	font-size: 3em;
	font-weight: bold;
	color: #e5e5e5;
}

.footerSubheading {
	margin-top: 10px;
	font-family: 'DM sans';
	font-size: 12px;
	color: #e5e5e5;
}

.footerC2a {
	font-family: 'Recoleta';
	font-size: 20px;
	line-height: 27px;
	text-align: right;
	color: #f5f5ed;
	text-decoration: none;
	cursor: pointer;
}

#div2 {
	display: grid;
	grid-template-columns: 1fr 1fr;
	width: 322px;
	grid-gap: 10px;
	gap: 10px;
	margin: auto;
	text-align: end;
}

#div1 {
	margin: auto;
}

.footerImg {
	width: 10vw;
	min-width: 100px;
	margin: auto;
}

.instaFot {
	display: inline-flex;
	justify-content: end;
	align-items: center;
	grid-gap: 5px;
	gap: 5px;
	margin-top: 10px;
	margin-bottom: 10px;
}

.instaFot > img {
	width: 50px;
}

@media screen and (max-width: 975px) {
	#div2,
	.footerC2a {
		text-align: center;
	}
	.footerHeading {
		width: -webkit-min-content;
		width: min-content;
		margin: auto;
	}
	.footerSubheading {
		text-align: center;
	}
	#div1 {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.instaFot > img {
		width: 25px;
	}
}

.thankyouTemp {
	display: none;
}

.heroSecContainer {
	position: relative;
  width: 100%;
  height: 100vh;
  /* min-height: 100vh; */
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.heroSecContainer::before {
  content: ' ';
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 800px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
  z-index: 4;
}

.heroBG {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
  z-index: 0;
}

.BuildingFront {
  position: absolute;
  top: 65vh;
  left: 0;
  width: 100vw;
  pointer-events: none;
  z-index: 3;
}

.heroText {
  position: absolute;
  top: 50vh;
  left: 50%;
  width: 100%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 1;
}

.heroName {
	font-family: 'Recoleta-Alt';
	font-weight: bold;
	font-size: 104px;
	line-height: 130%;
	text-align: center;
	color: #ffffff;
	max-width: 1100px;
	margin: auto;
}

.heroDesc {
	font-family: 'DM Sans';
  font-weight: normal;
	font-size: 24px;
	line-height: 160%;
	text-align: center;
	color: #ffffff;
  margin: auto;
  margin-top: 20px;
}

@media screen and (max-width: 960px) {
  .heroName {
    font-size: 48px;
  }
  .heroDesc {
    font-size: 14px;
  }
  .heroText {
    width: 95%;
    margin: auto;
    transition-property: all;
    transition-timing-function: linear;
  }
  .wave {
    width: 50px;
  }
}

.wave {
  -webkit-animation-name: wave-animation;
          animation-name: wave-animation;  /* Refers to the name of your @keyframes element below */
  -webkit-animation-duration: 2s;
          animation-duration: 2s;        /* Change to speed up or slow down */
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;  /* Never stop waving :) */
  -webkit-transform-origin: 70% 70%;
          transform-origin: 70% 70%;       /* Pivot around the bottom-left palm */
  display: inline-block;
}

@-webkit-keyframes wave-animation {
    0% { -webkit-transform: rotate( 0.0deg); transform: rotate( 0.0deg) }
   10% { -webkit-transform: rotate(14.0deg); transform: rotate(14.0deg) }  /* The following five values can be played with to make the waving more or less extreme */
   20% { -webkit-transform: rotate(-8.0deg); transform: rotate(-8.0deg) }
   30% { -webkit-transform: rotate(14.0deg); transform: rotate(14.0deg) }
   40% { -webkit-transform: rotate(-4.0deg); transform: rotate(-4.0deg) }
   50% { -webkit-transform: rotate(10.0deg); transform: rotate(10.0deg) }
   60% { -webkit-transform: rotate( 0.0deg); transform: rotate( 0.0deg) }  /* Reset for the last half to pause */
  100% { -webkit-transform: rotate( 0.0deg); transform: rotate( 0.0deg) }
}

@keyframes wave-animation {
    0% { -webkit-transform: rotate( 0.0deg); transform: rotate( 0.0deg) }
   10% { -webkit-transform: rotate(14.0deg); transform: rotate(14.0deg) }  /* The following five values can be played with to make the waving more or less extreme */
   20% { -webkit-transform: rotate(-8.0deg); transform: rotate(-8.0deg) }
   30% { -webkit-transform: rotate(14.0deg); transform: rotate(14.0deg) }
   40% { -webkit-transform: rotate(-4.0deg); transform: rotate(-4.0deg) }
   50% { -webkit-transform: rotate(10.0deg); transform: rotate(10.0deg) }
   60% { -webkit-transform: rotate( 0.0deg); transform: rotate( 0.0deg) }  /* Reset for the last half to pause */
  100% { -webkit-transform: rotate( 0.0deg); transform: rotate( 0.0deg) }
}

@font-face {
	font-family: 'Recoleta-Alt';
	src: url("/static/media/Recoleta Alt Bold.40523479.otf") format('opentype');
}

.last-img {
	display: block;
	position: absolute;
	object-fit: cover;
	top: 0;
	left: 0;
	max-width: 100vw;
}

.parallax-img-cont {
	height: 450px;
}

.afterHeroContainer {
	position: relative;
	width: 100%;
	height: 100vh;
	margin-bottom: 200px;
}

.afterHeroHeading {
	position: relative;
	width: 100%;
	height: 163px;
	max-width: 576px;
	margin: 50px auto;
}

.afterHeroBgHeading {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 1;
	font-family: 'Recoleta-Alt';
	font-style: normal;
	font-weight: bold;
	font-size: 120px;
	line-height: 135%;
	color: rgba(42, 117, 96, 0.1);
}

.afterHeroMainHeading {
	position: absolute;
	top: 40%;
	left: 50%;
	width: 100%;
	-webkit-transform: translate(-50%, -10%);
	        transform: translate(-50%, -10%);
	font-family: 'Recoleta';
	font-style: normal;
	font-weight: normal;
	font-size: 48px;
	line-height: 65px;
	color: #2a7560;
	z-index: 10;
	text-align: center;
}

.afterHeroImg {
	width: 20vw;
}

.afterHeroImg1 {
	position: absolute;
	top: 2vw;
	left: 15vw;
}

.afterHeroPara1 {
	position: absolute;
	font-family: 'Recoleta';
	font-size: 32px;
	line-height: 150%;
	color: #333333;
	max-width: 580px;
	width: 70vw;
	left: 30vw;
	top: 20vw;
}

.afterHeroPara3 {
	position: absolute;
	font-family: 'Recoleta';
	font-size: 32px;
	line-height: 150%;
	color: #333333;
	max-width: 580px;
	width: 70vw;
	left: 30vw;
	top: 43vw;
}

.afterHeroPara2 {
	position: absolute;
	font-family: 'Recoleta';
	font-size: 32px;
	line-height: 150%;
	color: #333333;
	max-width: 580px;
	width: 70vw;
	text-align: end;
	top: 33vw;
	right: 30vw;
}

.afterHeroImg2 {
	position: absolute;
	top: 15vw;
	left: calc(35vw + 580px);
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%);
}

.afterHeroImg5 {
	position: absolute;
	right: 25vw;
	top: 80vh;
}

.afterHeroImg4 {
	position: absolute;
	right: 60vw;
	top: 85vh;
}

.afterHeroImg3 {
	position: absolute;
	top: 25vw;
	left: 10vw;
	max-width: 400px;
}

.greenColor {
	color: #2a7560;
	font-weight: bold;
}

#cardsHeading2-cont {
	position: relative;
	width: 100%;
	height: 170px;
}

.cardsHeading2 {
	font-family: 'Inter';
	font-size: 6vw;
	line-height: 39px;
	text-align: center;
	color: #000000;
	position: absolute;
	top: 50%;
	left: 50%;
	width: 100%;
	text-align: center;
	-webkit-transform: translate(-50%, -10%);
	        transform: translate(-50%, -10%);
	z-index: 1000;
}

.cardsHeading2bg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 1;
	font-family: 'Recoleta-Alt';
	font-weight: bold;
	font-size: 17.8vw;
	line-height: 163px;
	color: rgba(51, 51, 51, 0.1);
}

.cardsPara {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: normal;
	font-size: 30px;
	line-height: 34px;
	color: #4f4f4f;
}

.blogsCont {
	position: relative;
	box-sizing: border-box;
	padding: 70px 0;
	width: 100%;
	background: #F6F6F0;
}

#blogCircle {
	position: absolute;
	top: 80%;
	left: 50vw;
	-webkit-transform: translateX(-50%);
	        transform: translateX(-50%);
	z-index: 1;
}

.blogCardCont {
	width: 95%;
	display: inline-flex;
	justify-content: space-between;
	align-items: center;
	grid-gap: 20px;
	gap: 20px;
	flex-wrap: wrap;
	max-width: 1180px;
	margin-top: 50px;
	position: relative;
	left: 50vw;
	-webkit-transform: translateX(-50%);
	        transform: translateX(-50%);
	z-index: 10;
}

.blogCard {
	margin: auto;
	max-width: 380px;
	text-decoration: none;
}

.blogCard > img {
	width: 380px;
	height: 280px;
	max-width: 95vw;
	object-fit: cover;
	border-radius: 8px;
	display: block;
	margin: auto;
	margin-bottom: 20px;
}

.blogHeading {
	font-family: 'DM Sans';
	font-weight: bold;
	font-size: 24px;
	line-height: 150%;
	color: #4f4f4f;
	margin-bottom: 8px;
	height: 108px;
}

.blogDesc {
	font-family: 'DM Sans';
	font-size: 20px;
	line-height: 150%;
	color: #828282;
}

@media screen and (max-width: 1024px) {
	.afterHeroContainer {
		height: 70vw;
	}
	.afterHeroImg1 {
		left: 20px;
	}
	.afterHeroPara2 {
		top: 40vw;
		right: 20px;
	}
	.afterHeroPara3 {
		top: 55vw;
		left: 20px;
	}
	.afterHeroImg2 {
		left: unset;
		right: 0;
	}
	.afterHeroImg5 {
		top: 60vw;
		right: 0;
	}
	.afterHeroImg4 {
		top: 60vw;
		right: 35vw;
	}
}

@media screen and (max-width: 960px) {
	.parallax-img-cont {
		height: 95vw;
		max-height: 760px;
	}
	.afterHeroContainer {
		height: 170vw;
		margin-bottom: 0px;
	}
	.afterHeroHeading {
		max-width: unset;
	}
	.afterHeroBgHeading {
		font-size: 19.5vw;
	}
	.afterHeroImg {
		width: 30vw;
	}
	.afterHeroMainHeading {
		font-size: 7.5vw;
		-webkit-transform: translate(-50%, -50%);
		        transform: translate(-50%, -50%);
	}
	.afterHeroImg1 {
		top: 100px;
		left: 20px;
	}
	.afterHeroPara1 {
		font-size: 20px;
		top: 65vw;
		left: 20px;
	}
	.afterHeroPara2 {
		font-size: 20px;
		top: 100vw;
		right: 20px;
	}
	.afterHeroPara3 {
		font-size: 20px;
		top: 125vw;
		left: 20px;
	}
	.afterHeroImg2 {
		top: 55vw;
		left: unset;
		right: 0;
	}
	.afterHeroImg3 {
		top: 135vw;
		left: 10px;
	}
	.afterHeroImg5 {
		top: 140vw;
		right: 0;
	}
	.afterHeroImg4 {
		top: 140vw;
		right: 35vw;
	}
	.blogHeading {
		height: -webkit-fit-content;
		height: -moz-fit-content;
		height: fit-content;
	}
}

.secCard {
	display: none;
}

#tesr {
	background: #f5f5eb;
	padding-top: 60px;
	padding-bottom: 60px;
}

.main-carousel-cont {
	overflow-x: scroll;
	scrollbar-width: thin;
	cursor: grab;
	scroll-behavior: smooth;
}

.main-carousel-cont::-webkit-scrollbar {
	display: none;
}
.main-carousel-cont::-webkit-scrollbar-thumb {
	display: none;
}

.mainCarousel {
	display: inline-flex;
	grid-gap: 50px;
	gap: 50px;
	position: relative;
	align-items: center;
	padding-right: 25px;
}

.carouselImg {
	align-self: center;
	box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.16);
	border-radius: 8px;
	max-height: 750px;
	max-width: 550px;
}

#img-0 {
	z-index: 10;
	align-self: center;
}

#img-1 {
	z-index: 5;
}

#img-2 {
	z-index: 1;
}

#img-3 {
	z-index: 30;
}

#img-4 {
	z-index: 1;
}

#img-5 {
	z-index: 50;
}

#img-6 {
	z-index: 60;
}

#img-7 {
	z-index: 40;
}

#img-8 {
	z-index: 90;
}

#img-9 {
	z-index: 1000;
}

#img-10 {
	z-index: 12;
}

#img-11 {
	z-index: 4;
}

#img-12 {
	z-index: 7;

}

#img-13 {
	z-index: 20;

}

#img-14 {
	z-index: 15;
}

#img-15 {
	z-index: 15;
}

#img-16 {
	z-index: 10;
}

#img-17 {
	z-index: 1;
}

.deskCard {
	width: 590px;
	margin-left: 15vw;
	z-index: 100;
}

.deskHeading2bg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 1;
	font-family: 'Recoleta-Alt';
	font-style: normal;
	font-weight: bold;
	font-size: 120px;
	line-height: 163px;
	color: rgba(42, 117, 96, 0.1);
}

.deskHeading2 {
	font-family: 'Recoleta';
	font-size: 40px;
	line-height: 54px;
	color: #2a7560;
	text-align: center;
	position: absolute;
	top: 50%;
	left: 50%;
	width: 100%;
	-webkit-transform: translate(-50%, -10%);
	        transform: translate(-50%, -10%);
	z-index: 5;
}

.dropdownCont {
	display: none;
}

.carouselDD {
	background: #2a7560;
	border-radius: 56px;
	padding: 10px 20px;
	font-family: 'DM Sans';
	font-weight: 500;
	font-size: 18px;
	line-height: 31px;
	color: #ffffff;
	text-align: center;
	width: -moz-fit-content;
	width: -webkit-fit-content;
	width: fit-content;
	cursor: pointer;
}

.ddContMain:hover .dropdownCont {
	background: #2a7560;
	position: absolute;
	display: block;
	z-index: 1000;
	font-family: 'DM Sans';
	color: white;
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;
}

.ddContMain:hover .carouselDD {
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	box-sizing: border-box;
}

.ddContMain {
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
}

.dropdownElem {
	padding: 10px 20px;
	box-sizing: border-box;
	word-wrap: normal;
	cursor: pointer;
	text-decoration: none !important;
	color: white;
}

.dropdownElemLast {
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;
}

.dropdownElem:hover {
	background: #2b9778;
}

@media screen and (max-width: 960px) {
	.deskCard {
		display: none;
	}
	.secCard {
		display: block;
		width: 95%;
		margin: auto;
		margin-bottom: 50px;
	}
	.carouselImg {
		max-width: 250px;
		max-height: 450px;
	}
	.cardsPara {
		font-size: 20px;
		line-height: 24px;
	}
}

.testimonialContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 50px;
	max-width: 100vw;
}

.testimonailContCont {
	margin: 50px auto;
	box-sizing: border-box;
	padding: 80px 10px;
  position: relative;
}

.testimonialCircle {
  position: absolute;
  top: 0;
  right: 0;
  -webkit-transform: translateX(20%);
          transform: translateX(20%);
}

.carousel {
	max-width: 80vw !important;
}

.chevronBtns {
	display: block;
	cursor: pointer;
  z-index: 10;
}

.cardCont {
	max-width: 1080px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	grid-gap: 20px;
	gap: 20px;
	margin: auto;
}

.quoteImg {
	display: block;
	box-shadow: 0px 4px 100px rgba(0, 0, 0, 0.16);
	border-radius: 8px;
	max-width: 380px;
}

.quoteHeading {
	font-family: 'DM Sans';
	margin-bottom: 8px;
	font-weight: bold;
	font-size: 40px;
	line-height: 140%;
	letter-spacing: 0.2px;
	color: #2a7560;
	text-align: start;
}

.quote {
	font-family: 'DM Sans';
	margin-bottom: 24px;
	font-size: 24px;
	line-height: 140%;
	letter-spacing: 0.2px;
	color: #4f4f4f;
	text-align: start;
}

.quotePerson {
	font-family: 'DM Sans';
	margin-bottom: 8px;
	font-weight: bold;
	font-size: 32px;
	line-height: 140%;
	letter-spacing: 0.2px;
	color: #4f4f4f;
	text-align: start;
}

.quoteBio {
	font-family: 'DM Sans';
	font-size: 24px;
	line-height: 140%;
	letter-spacing: 0.2px;
	color: #828282;
	text-align: start;
}

.mobChevronCont {
  display: none;
}

@media screen and (max-width: 960px) {
	.cardCont {
		flex-direction: column-reverse;
		padding: 0 20px;
		box-sizing: border-box;
	}
	.chevronBtns {
		display: none;
	}
	.carousel {
		max-width: 100% !important;
	}
  .testimonialContainer {
    display: block;
  }
  .mobChevronCont {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  .quoteHeading {
    text-align: center;
  }
  .testimonailContCont {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.imageTextBlock {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 95%;
	max-width: 1180px;
	margin: auto;
	flex: 1 1;
	z-index: 10;
	position: relative;
}

.bgcWhite {
	background-color: #fff;
}

.textBlockImage {
	flex-direction: row-reverse;
}

.imgTextBlkImgCont {
	max-width: 580px;
	margin: auto;
}

.imgTextBlkImgCont img {
	max-width: 580px;
}

.imgTextBlkHeading {
	font-family: 'Recoleta';
	font-size: 48px;
	line-height: 65px;
	color: #2a7560;
}

.imgTextBlkDesc {
	font-family: 'DM Sans';
	font-weight: 500;
	font-size: 24px;
	line-height: 160%;
	color: #828282;
}

@media screen and (max-width: 960px) {
	.imageTextBlock {
		display: block;
	}
	.imgTextBlkTextCont {
		margin-top: 30px;
	}
	.imgTextBlkImgCont {
		max-width: 95vw;
	}
	.imgTextBlkImgCont img {
		max-width: 95vw;
	}
	.imgTextBlkHeading {
		font-size: 40px;
		line-height: 55px;
	}
	.imgTextBlkDesc {
		font-size: 18px;
	}
}

.privacyPolicyContainer {
  max-width: 1110px;
  width: 95%;
  margin: auto;
  margin-top: 150px;
  font-family: 'DM Sans';
  font-size: 18px;
}

.privacyPolicyContainer > h1 ,
.privacyPolicyContainer > h2 {
  font-family: 'Recoleta';
}

.privacyPolicyContainer {
  max-width: 1110px;
  width: 95%;
  margin: auto;
  margin-top: 150px;
  font-family: 'DM Sans';
  font-size: 18px;
}

.privacyPolicyContainer > h1 ,
.privacyPolicyContainer > h2 {
  font-family: 'Recoleta';
}

.propertyPageMainCont {
	background: #f8f5f0;
	padding-bottom: 50px;
}

.propertyPageTopHead {
	padding-top: 30px;
	font-family: 'Playfair Display';
	font-size: 24px;
	line-height: 130%;
	color: #bb9c64;
	text-align: center;
	margin-top: 20px;
}

.propertyPageTopSubHead {
	font-family: 'Vollkorn';
	font-size: 16px;
	line-height: 130%;
	letter-spacing: 0.08em;
	color: #bb9c64;
	text-align: center;
}

.SycTitle {
	max-width: 87vw;
	margin: auto;
	margin-top: 45px;
	font-family: 'Motherva';
	font-size: 16vw;
	line-height: 135%;
	text-align: center;
	color: #bb9c64;
	margin-bottom: 50px;
}

.propImgsMain {
	display: block;
	margin: auto;
	width: 42vw;
	height: 43vw;
	object-fit: cover;
	box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.05);
}

.secImgsCont {
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	z-index: 10;
	-webkit-transform: translateY(-20vw);
	        transform: translateY(-20vw);
}

.propImgsSec {
	display: block;
	box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.16);
	width: 26.5vw;
	height: 36vw;
	object-fit: cover;
}

#propImgsSec1 {
	-webkit-transform: translate(-5.1vw, 8.3vw);
	        transform: translate(-5.1vw, 8.3vw);
}

#propImgsSec2 {
	-webkit-transform: translateX(13.25vw);
	        transform: translateX(13.25vw);
	align-self: flex-start;
	margin-right: 8.3vw;
}

#propImgsSec3 {
	-webkit-transform: translateY(13.25vw);
	        transform: translateY(13.25vw);
	width: 18.2vw;
	object-fit: cover;
}

.propertyInfoPara1 {
	max-width: 790px;
	margin: auto;
	text-align: center;
	font-family: 'Motherva';
	font-weight: 300;
	font-size: 120px;
	line-height: 100%;
	color: #4f4f4f;
}

.propertyInfoDesc1 {
	max-width: 643px;
	margin: auto;
	margin-top: 15px;
	font-family: 'DM Sans';
	font-size: 24px;
	line-height: 31px;
	text-align: center;
	color: #828282;
}

.finalImgCont {
	margin-top: 100px;
	position: relative;
	padding-bottom: 22vw;
}

.last2Img1 {
	width: 51.3vw;
	height: 41.8vw;
	object-fit: cover;
	margin-left: 9.8vw;
}

.last2Img2 {
	width: 35.76vw;
	height: 41.8vw;
	object-fit: cover;
	position: absolute;
	right: 12.84vw;
	z-index: 10;
	top: 19.72vw;
	box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.08);
}

.SycTitleSmall {
	margin: auto;
	font-family: 'Motherva';
	font-size: 250px;
	line-height: 135%;
	color: #bb9c64;
	text-align: center;
}

.propMainTitleSize {
	font-family: 'Playfair Display';
	font-size: 120px;
	line-height: 120%;
	color: #4f4f4f;
}

.work {
	margin-left: 14.86vw;
}

.liveAndLove {
	margin-left: 23.47vw;
}

.underThe {
	margin-left: 45.69vw;
}

.getInTouchCont {
	display: inline-flex;
	grid-gap: 8px;
	gap: 8px;
	align-items: center;
	justify-content: center;
	width: 100%;
	margin-top: 15px;
	cursor: pointer;
	font-family: 'Playfair Display';
	font-size: 24px;
	line-height: 32px;
	color: #000000;
}

.POI {
	margin: auto;
	margin-top: 100px;
	max-width: 661px;
	font-family: 'Motherva';
	font-style: normal;
	font-weight: 300;
	font-size: 120px;
	line-height: 80.33%;
	text-align: center;
	color: #4f4f4f;
	margin-bottom: 30px;
}

.iconsCont {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	grid-gap: 20px;
	gap: 20px;
	max-width: 1200px;
	margin: 120px auto;
}

.iconBox {
	width: 280px;
	height: 234px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	font-family: 'Playfair Display';
	font-size: 24px;
	line-height: 32px;
	color: #bb9c64;
	text-align: center;
}

.nearPropImgs {
	border-radius: 8px;
	width: 380px;
	height: 280px;
	object-fit: cover;
	max-width: 90vw;
}

.propCarousel {
	margin-top: 40px;
}

.propMainCarousel {
	margin-left: 9vw;
	margin-right: 5vw;
	grid-gap: 20px;
	gap: 20px;
	align-items: flex-start;
}

.propCarouselTitle {
	font-family: 'Playfair Display';
	font-weight: bold;
	font-size: 24px;
	line-height: 150%;
	color: #4f4f4f;
	margin-bottom: 8px;
	margin-top: 15px;
}

.propCarouUL {
	list-style: none;
	font-family: 'DM Sans';
	font-size: 16px;
	line-height: 150%;
	color: #828282;
}

.topC2aSyc {
	width: 100%;
	background-color: #bb9c64;
	color: white;
	padding: 10px;
	text-align: center;
	font-family: 'Inter';
	box-sizing: border-box;
	position: fixed;
	top: 0;
	z-index: 100;
}

.topc2a {
	cursor: pointer;
	font-weight: bold;
}

.topc2a:hover {
	text-decoration: underline;
}

@media screen and (max-width: 960px) {
	.propertyPageTopHead {
		font-size: 18px;
		margin-top: 50px;
	}
	.propertyPageTopSubHead {
		font-size: 12px;
	}
	.propertyInfoPara1 {
		font-size: 60px;
	}
	.propMainTitleSize {
		font-size: 58px;
	}
	.POI {
		font-size: 60px;
		margin-top: 40px;
	}
	.work {
		margin-left: 8.86vw;
		margin-top: 50px;
	}
	.liveAndLove {
		margin-left: 15.47vw;
	}
	.underThe {
		margin-left: 30.69vw;
	}
	.finalImgCont {
		margin-top: 40px;
		padding-bottom: 12vw;
	}
	.propMainCarousel {
		margin-left: 5vw;
		margin-right: 3vw;
	}
	.iconBox {
		height: -webkit-fit-content;
		height: -moz-fit-content;
		height: fit-content;
	}
	.propertyInfoDesc1 {
		font-size: 18px;
		line-height: 22px;
	}
	.iconsCont {
		margin: 0 auto;
	}
	.SycTitle {
		font-size: 19vw;
	}
	.SycTitleSmall {
		font-size: 66px;
	}
}

.propertyPageMainCont {
	background: #fff;
	padding-bottom: 50px;
}

.propertyPageTopHead {
	padding-top: 30px;
	font-family: 'Playfair Display';
	font-size: 24px;
	line-height: 130%;
	color: #bb9c64;
	text-align: center;
	margin-top: 20px;
}

.propertyPageTopSubHead {
	font-family: 'Vollkorn';
	font-size: 16px;
	line-height: 130%;
	letter-spacing: 0.08em;
	color: #bb9c64;
	text-align: center;
}

.UnoTitle {
	max-width: 87vw;
	margin: auto;
	margin-top: 45px;
	font-family: 'Abril Fatface';
	font-size: 12vw;
	line-height: 135%;
	text-align: center;
	color: #d99e37;
	margin-bottom: 50px;
}

.propImgsMain {
	display: block;
	margin: auto;
	width: 42vw;
	height: 43vw;
	object-fit: cover;
	box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.05);
}

.secImgsCont {
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	z-index: 10;
	-webkit-transform: translateY(-20vw);
	        transform: translateY(-20vw);
}

.propImgsSec {
	display: block;
	box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.16);
	width: 26.5vw;
	height: 36vw;
	object-fit: cover;
}

#propImgsSec1 {
	-webkit-transform: translate(-5.1vw, 8.3vw);
	        transform: translate(-5.1vw, 8.3vw);
}

#propImgsSec2 {
	-webkit-transform: translateX(13.25vw);
	        transform: translateX(13.25vw);
	align-self: flex-start;
	margin-right: 8.3vw;
}

#propImgsSec3 {
	-webkit-transform: translateY(13.25vw);
	        transform: translateY(13.25vw);
	width: 18.2vw;
	object-fit: cover;
}

.propertyInfoPara1Uno {
	max-width: 1070px;
	margin: auto;
	text-align: center;
	font-family: 'Abril Fatface';
	font-size: 120px;
	line-height: 100%;
	color: #c65e5a;
}

.propertyInfoDesc1Uno {
	max-width: 650px;
	margin: auto;
	margin-top: 25px;
	font-family: 'DM Sans';
	font-size: 24px;
	line-height: 31px;
	text-align: center;
	color: #080401;
}

.finalImgCont {
	margin-top: 100px;
	position: relative;
	padding-bottom: 22vw;
}

.last2Img1 {
	width: 51.3vw;
	height: 41.8vw;
	object-fit: cover;
	margin-left: 9.8vw;
}

.last2Img2 {
	width: 35.76vw;
	height: 41.8vw;
	object-fit: cover;
	position: absolute;
	right: 12.84vw;
	z-index: 10;
	top: 19.72vw;
	box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.08);
}

.UnoTitleSmall {
	margin: auto;
	font-family: 'Abril Fatface';
	font-size: 120px;
	line-height: 135%;
	color: #c38b78;
	text-align: center;
}

.propMainTitleSizeUno {
	font-family: 'Inter';
	font-size: 120px;
	line-height: 120%;
	color: #4f4f4f;
	text-align: center;
}

.getInTouchCont {
	display: inline-flex;
	grid-gap: 8px;
	gap: 8px;
	align-items: center;
	justify-content: center;
	width: 100%;
	margin-top: 15px;
	cursor: pointer;
	font-family: 'Playfair Display';
	font-size: 24px;
	line-height: 32px;
	color: #000000;
}

.POIUno {
	margin: auto;
	margin-top: 100px;
	max-width: 944px;
	font-family: 'Abril Fatface';
	font-size: 120px;
	line-height: 80.33%;
	text-align: center;
	color: #4f4f4f;
	margin-bottom: 30px;
}

.iconsCont {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	grid-gap: 20px;
	gap: 20px;
	max-width: 1200px;
	margin: 120px auto;
}

.iconBoxUno {
	width: 280px;
	height: 234px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	font-family: 'Playfair Display';
	font-size: 24px;
	line-height: 32px;
	color: #c38b78;
	text-align: center;
}

.iconsSVG > path {
	fill: #c38b78 !important;
}

.iconsSVG > g > path {
	fill: #c38b78 !important;
}

.iconsSVG > g {
	fill: #c38b78 !important;
}

.nearPropImgs {
	border-radius: 8px;
	width: 380px;
	height: 280px;
	object-fit: cover;
	max-width: 90vw;
}

.propCarousel {
	margin-top: 40px;
}

.propMainCarousel {
	margin-left: 9vw;
	margin-right: 5vw;
	grid-gap: 20px;
	gap: 20px;
	align-items: flex-start;
}

.propCarouselTitle {
	font-family: 'Playfair Display';
	font-weight: bold;
	font-size: 24px;
	line-height: 150%;
	color: #4f4f4f;
	margin-bottom: 8px;
	margin-top: 15px;
}

.propCarouUL {
	list-style: none;
	font-family: 'DM Sans';
	font-size: 16px;
	line-height: 150%;
	color: #828282;
}

.topC2aUno {
	width: 100%;
	background-color: #d99e37;
	color: white;
	padding: 10px;
	text-align: center;
	font-family: 'Inter';
	box-sizing: border-box;
	position: fixed;
	top: 0;
	z-index: 100;
}

.topc2a {
	cursor: pointer;
	font-weight: bold;
}

.topc2a:hover {
	text-decoration: underline;
}

@media screen and (max-width: 960px) {
	.propertyPageTopHead {
		font-size: 18px;
		width: 95%;
		margin: auto;
		margin-top: 50px;
	}
	.propertyPageTopSubHead {
		font-size: 12px;
	}
	.propertyInfoPara1Uno {
		font-size: 60px;
	}
	.propMainTitleSizeUno,
	.UnoTitleSmall {
		font-size: 58px;
	}
	.POIUno {
		font-size: 60px;
		margin-top: 40px;
	}
	.finalImgCont {
		margin-top: 40px;
		padding-bottom: 12vw;
	}
	.propMainCarousel {
		margin-left: 5vw;
		margin-right: 3vw;
	}
	.iconBoxUno {
		height: -webkit-fit-content;
		height: -moz-fit-content;
		height: fit-content;
	}
	.propertyInfoDesc1Uno {
		font-size: 18px;
		line-height: 22px;
	}
	.iconsCont {
		margin: 0 auto;
	}
	.workUno {
		margin-top: 70px;
	}
	.unoCont {
		width: 90%;
		margin: auto;
	}
	.UnoImg1,
	.UnoImg2,
	.UnoImg3 {
		margin: 0 !important;
	}
	.UnoImg3 {
		width: 26.5vw !important;
		height: 36vw !important;
		object-fit: cover !important;
	}
	.UnoImg1 {
		-webkit-transform: none !important;
		        transform: none !important;
	}
}

.ombrePageMainCont {
	background: #dfe7e8;
	padding-bottom: 50px;
}

.propertyPageTopHead {
	padding-top: 30px;
	font-family: 'Playfair Display';
	font-size: 24px;
	line-height: 130%;
	color: #557054;
	text-align: center;
	margin-top: 20px;
}

.propertyPageTopSubHead {
	font-family: 'Vollkorn';
	font-size: 16px;
	line-height: 130%;
	letter-spacing: 0.08em;
	color: #557054;
	text-align: center;
}

.OmbTitle {
	max-width: 87vw;
	margin: auto;
	margin-top: 45px;
	font-family: 'Victor Serif';
	font-size: 16vw;
	line-height: 135%;
	text-align: center;
	color: #446063;
}

.DerSubTitle {
	font-family: 'Victor Serif';
	font-style: italic;
	text-align: center;
	margin: auto;
	font-size: 18px;
	line-height: 130%;
	margin-bottom: 50px;
	max-width: 1110px;
	width: 95vw;
	color: #828282;
}

.propImgsMain {
	display: block;
	margin: auto;
	width: 42vw;
	height: 43vw;
	object-fit: cover;
	box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.05);
}

.secImgsCont {
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	z-index: 10;
	-webkit-transform: translateY(-20vw);
	        transform: translateY(-20vw);
}

.propImgsSec {
	display: block;
	box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.16);
	width: 26.5vw;
	height: 36vw;
	object-fit: cover;
}

#propImgsSec1 {
	-webkit-transform: translate(-5.1vw, 8.3vw);
	        transform: translate(-5.1vw, 8.3vw);
}

#propImgsSec2 {
	align-self: flex-start;
	margin-right: 8.3vw;
}

.propImgsSec2Omb,
.propImgsSec3Omb {
	-webkit-transform: translateX(13.25vw) scale(-1, 1);
	        transform: translateX(13.25vw) scale(-1, 1);
}

#propImgsSec3 {
	width: 18.2vw;
	object-fit: cover;
}

.propertyInfoPara1Der {
	max-width: 790px;
	margin: auto;
	text-align: center;
	font-family: 'Motherva';
	font-weight: 300;
	font-size: 120px;
	line-height: 100%;
	color: #c2948b;
}

.propertyInfoDesc1Der {
	max-width: 643px;
	width: 95vw;
	margin: auto;
	margin-top: 15px;
	font-family: 'Victor Serif';
	font-style: italic;
	font-size: 24px;
	line-height: 31px;
	text-align: center;
	color: #828282;
}

.finalImgCont {
	margin-top: 100px;
	position: relative;
	padding-bottom: 22vw;
}

.last2Img1 {
	width: 51.3vw;
	height: 41.8vw;
	object-fit: cover;
	margin-left: 9.8vw;
}

.last2Img2 {
	width: 35.76vw;
	height: 41.8vw;
	object-fit: cover;
	position: absolute;
	right: 12.84vw;
	z-index: 10;
	top: 19.72vw;
	box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.08);
}

.OmbTitleSmall {
	margin: auto;
	font-family: 'Victor Serif';
	font-size: 280px;
	line-height: 135%;
	color: #446063;
	text-align: center;
}

.propMainTitleSizeDer {
	font-family: 'Playfair Display';
	font-size: 100px;
	line-height: 120%;
	/* color: #4f4f4f; */
	color: #c2948b;
}

.work {
	margin-left: 14.86vw;
}

.liveAndLove {
	margin-left: 23.47vw;
}

.underThe {
	margin-left: 40vw;
}

.yourselfAt {
	margin-left: 55vw;
}

.getInTouchCont {
	display: inline-flex;
	grid-gap: 8px;
	gap: 8px;
	align-items: center;
	justify-content: center;
	width: 100%;
	margin-top: 15px;
	cursor: pointer;
	font-family: 'Playfair Display';
	font-size: 24px;
	line-height: 32px;
	color: #000000;
}

.POIDer {
	margin: auto;
	margin-top: 100px;
	max-width: 661px;
	font-family: 'Motherva';
	font-style: normal;
	font-weight: 300;
	font-size: 120px;
	line-height: 80.33%;
	text-align: center;
	/* color: #4f4f4f; */
	color: #c2948b;
	margin-bottom: 30px;
}

.iconsCont {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	grid-gap: 20px;
	gap: 20px;
	max-width: 1200px;
	margin: 120px auto;
}

.iconBoxOmb {
	width: 280px;
	height: 234px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	font-family: 'Playfair Display';
	font-size: 24px;
	line-height: 32px;
	color: #446063;
	text-align: center;
}

.iconsSVGOmb > path {
	fill: #446063;
}

.iconsSVGOmb > g > path {
	fill: #446063;
}

.iconsSVGOmb > g {
	fill: #446063;
}

.nearPropImgs {
	border-radius: 8px;
	width: 380px;
	height: 280px;
	object-fit: cover;
	max-width: 90vw;
}

.propCarousel {
	margin-top: 40px;
}

.propMainCarousel {
	margin-left: 9vw;
	margin-right: 5vw;
	grid-gap: 20px;
	gap: 20px;
	align-items: flex-start;
}

.propCarouselTitle {
	font-family: 'Playfair Display';
	font-weight: bold;
	font-size: 24px;
	line-height: 150%;
	color: #4f4f4f;
	margin-bottom: 8px;
	margin-top: 15px;
}

.propCarouUL {
	list-style: none;
	font-family: 'DM Sans';
	font-size: 16px;
	line-height: 150%;
	color: #828282;
}

.topC2aOmbre {
	width: 100%;
	background-color: #446063;
	color: #dfe7e8;
	padding: 15px;
	font-size: 18px;
	text-align: center;
	font-family: 'Inter';
	box-sizing: border-box;
	position: fixed;
	top: 0;
	z-index: 100;
}

.topc2a {
	cursor: pointer;
	font-weight: bold;
	display: inline-flex;
}

.headChevron {
	display: none;
	width: 21px;
	height: 21px;
}

.topc2a:hover {
	text-decoration: underline;
}

.topc2a:hover > .headChevron {
	display: block;
}

.headChevron > path {
	fill: #dfe7e8 !important;
}

.POInearImg {
	display: block;
	margin: 20px auto;
	width: 600px;
	max-width: 95vw;
	height: 600px;
	max-height: 95vw;
	border: none;
}

@media screen and (max-width: 960px) {
	.propertyPageTopHead {
		font-size: 18px;
		margin-top: 70px !important;
	}
	.topC2aOmbre {
		padding: 10px;
	}
	.propertyPageTopSubHead {
		font-size: 12px;
	}
	.propertyInfoPara1Der {
		font-size: 60px;
	}
	.propMainTitleSizeDer {
		font-size: 52px;
	}
	.POIDer {
		font-size: 60px;
		margin-top: 40px;
	}
	.work {
		margin-left: 8.86vw;
		margin-top: 50px;
	}
	.liveAndLove {
		margin-left: 12.47vw;
	}
	.underThe {
		margin-left: 20.69vw;
	}

	.yourselfAt {
		margin-left: 25vw;
	}
	.finalImgCont {
		margin-top: 40px;
		padding-bottom: 12vw;
	}
	.propMainCarousel {
		margin-left: 5vw;
		margin-right: 3vw;
	}
	.iconBoxDer {
		height: -webkit-fit-content;
		height: -moz-fit-content;
		height: fit-content;
	}
	.propertyInfoDesc1Der {
		font-size: 18px;
		line-height: 22px;
	}
	.iconsCont {
		margin: 0 auto;
	}
	.OmbTitle {
		font-size: 22vw;
	}
	.OmbTitleSmall {
		font-size: 70px;
	}
	.propImgsSec {
		width: 29vw;
		height: 39vw;
	}
	.propImgsSec2Omb {
		-webkit-transform: translateX(10.25vw) scale(-1, 1);
		        transform: translateX(10.25vw) scale(-1, 1);
		align-self: flex-start;
		/* margin-right: 9.3vw; */
	}
}

.thankyouCont {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin-top: 10vh;
	margin-bottom: 5vh;
}

.thankyouCont > img {
	max-width: 600px;
	width: 80vw;
	display: block;
	margin-top: 50px;
}

.thankyouCont > h2 {
	text-align: center;
	max-width: 950px;
	width: 90vw;
	font-family: 'DM Sans';
	margin: 50px auto;
}

.thankyouCont > a {
	text-decoration: none;
	background: #2a7560;
	border-radius: 56px;
	padding: 12px 40px;
	font-family: 'DM Sans';
	font-weight: 500;
	font-size: 24px;
	line-height: 31px;
	color: #ffffff;
	text-align: center;
	cursor: pointer;
}

@media screen and (max-width: 500px) {
	.thankyouCont > a {
		font-size: 14px;
		line-height: 18px;
		padding: 8px 20px;
	}
}

.mainCont {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin-top: 10vh;
	margin-bottom: 5vh;
}

.mainCont > img {
	max-width: 1100px;
	width: 80vw;
	display: block;
}

.mainCont > a {
	text-decoration: none;
	background: #2a7560;
	border-radius: 56px;
	padding: 12px 40px;
	font-family: 'DM Sans';
	font-weight: 500;
	font-size: 24px;
	line-height: 31px;
	color: #ffffff;
	text-align: center;
	cursor: pointer;
}

@media screen and (max-width: 500px) {
	.mainCont > a {
		font-size: 14px;
		line-height: 18px;
		padding: 8px 20px;
	}
}

.propertyPageMainCont {
	background: #f8f5f0;
	padding-bottom: 50px;
}

.propertyPageTopHead {
	padding-top: 30px;
	font-family: 'Playfair Display';
	font-size: 24px;
	line-height: 130%;
	color: #557054;
	text-align: center;
	margin-top: 20px;
}

.propertyPageTopSubHead {
	font-family: 'Vollkorn';
	font-size: 16px;
	line-height: 130%;
	letter-spacing: 0.08em;
	color: #557054;
	text-align: center;
}

.DerTitle {
	max-width: 87vw;
	margin: auto;
	margin-top: 45px;
	font-family: 'Victor Serif';
	font-size: 16vw;
	line-height: 135%;
	text-align: center;
	color: #557054;
}

.DerSubTitle {
	font-family: 'Victor Serif';
	font-style: italic;
	text-align: center;
	margin: auto;
	font-size: 18px;
	line-height: 130%;
	margin-bottom: 50px;
	max-width: 1110px;
	width: 95vw;
	color: #828282;
}

.propImgsMain {
	display: block;
	margin: auto;
	width: 42vw;
	height: 43vw;
	object-fit: cover;
	box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.05);
}

.secImgsCont {
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	z-index: 10;
	-webkit-transform: translateY(-20vw);
	        transform: translateY(-20vw);
}

.propImgsSec {
	display: block;
	box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.16);
	width: 26.5vw;
	height: 36vw;
	object-fit: cover;
}

#propImgsSec1 {
	-webkit-transform: translate(-5.1vw, 8.3vw);
	        transform: translate(-5.1vw, 8.3vw);
}

#propImgsSec2 {
	-webkit-transform: translateX(13.25vw);
	        transform: translateX(13.25vw);
	align-self: flex-start;
	margin-right: 8.3vw;
}

#propImgsSec3 {
	-webkit-transform: translateY(13.25vw);
	        transform: translateY(13.25vw);
	width: 18.2vw;
	object-fit: cover;
}

.propertyInfoPara1Der {
	max-width: 790px;
	margin: auto;
	text-align: center;
	font-family: 'Motherva';
	font-weight: 300;
	font-size: 120px;
	line-height: 100%;
	color: #c2948b;
}

.propertyInfoDesc1Der {
	max-width: 643px;
	width: 95vw;
	margin: auto;
	margin-top: 15px;
	font-family: 'Victor Serif';
	font-style: italic;
	font-size: 24px;
	line-height: 31px;
	text-align: center;
	color: #828282;
}

.finalImgCont {
	margin-top: 100px;
	position: relative;
	padding-bottom: 22vw;
}

.last2Img1 {
	width: 51.3vw;
	height: 41.8vw;
	object-fit: cover;
	margin-left: 9.8vw;
}

.last2Img2 {
	width: 35.76vw;
	height: 41.8vw;
	object-fit: cover;
	position: absolute;
	right: 12.84vw;
	z-index: 10;
	top: 19.72vw;
	box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.08);
}

.DerTitleSmall {
	margin: auto;
	font-family: 'Victor Serif';
	font-size: 280px;
	line-height: 135%;
	color: #557054;
	text-align: center;
}

.propMainTitleSizeDer {
	font-family: 'Playfair Display';
	font-size: 100px;
	line-height: 120%;
	/* color: #4f4f4f; */
	color: #c2948b;
}

.work {
	margin-left: 14.86vw;
}

.liveAndLove {
	margin-left: 23.47vw;
}

.underThe {
	margin-left: 40vw;
}

.yourselfAt {
	margin-left: 55vw;
}

.getInTouchCont {
	display: inline-flex;
	grid-gap: 8px;
	gap: 8px;
	align-items: center;
	justify-content: center;
	width: 100%;
	margin-top: 15px;
	cursor: pointer;
	font-family: 'Playfair Display';
	font-size: 24px;
	line-height: 32px;
	color: #000000;
}

.POIDer {
	margin: auto;
	margin-top: 100px;
	max-width: 661px;
	font-family: 'Motherva';
	font-style: normal;
	font-weight: 300;
	font-size: 120px;
	line-height: 80.33%;
	text-align: center;
	/* color: #4f4f4f; */
	color: #c2948b;
	margin-bottom: 30px;
}

.iconsCont {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	grid-gap: 20px;
	gap: 20px;
	max-width: 1200px;
	margin: 120px auto;
}

.iconBoxDer {
	width: 280px;
	height: 234px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	font-family: 'Playfair Display';
	font-size: 24px;
	line-height: 32px;
	color: #557054;
	text-align: center;
}

.iconsSVGDer > path {
	fill: #557054;
}

.iconsSVGDer > g > path {
	fill: #557054;
}

.iconsSVGDer > g {
	fill: #557054;
}

.nearPropImgs {
	border-radius: 8px;
	width: 380px;
	height: 280px;
	object-fit: cover;
	max-width: 90vw;
}

.propCarousel {
	margin-top: 40px;
}

.propMainCarousel {
	margin-left: 9vw;
	margin-right: 5vw;
	grid-gap: 20px;
	gap: 20px;
	align-items: flex-start;
}

.propCarouselTitle {
	font-family: 'Playfair Display';
	font-weight: bold;
	font-size: 24px;
	line-height: 150%;
	color: #4f4f4f;
	margin-bottom: 8px;
	margin-top: 15px;
}

.propCarouUL {
	list-style: none;
	font-family: 'DM Sans';
	font-size: 16px;
	line-height: 150%;
	color: #828282;
}

.topC2aDer {
	width: 100%;
	background-color: #557054;
	color: white;
	padding: 15px;
	font-size: 18px;
	text-align: center;
	font-family: 'Inter';
	box-sizing: border-box;
	position: fixed;
	top: 0;
	z-index: 100;
}

.topc2a {
	cursor: pointer;
	font-weight: bold;
}

.topc2a:hover {
	text-decoration: underline;
}

.POInearImg {
	display: block;
	margin: 20px auto;
	width: 600px;
	max-width: 95vw;
}

@media screen and (max-width: 960px) {
	.propertyPageTopHead {
		font-size: 18px;
		margin-top: 50px;
	}
	.propertyPageTopSubHead {
		font-size: 12px;
	}
	.propertyInfoPara1Der {
		font-size: 60px;
	}
	.propMainTitleSizeDer {
		font-size: 52px;
	}
	.POIDer {
		font-size: 60px;
		margin-top: 40px;
	}
	.work {
		margin-left: 8.86vw;
		margin-top: 50px;
	}
	.liveAndLove {
		margin-left: 12.47vw;
	}
	.underThe {
		margin-left: 20.69vw;
	}

	.yourselfAt {
		margin-left: 25vw;
	}
	.finalImgCont {
		margin-top: 40px;
		padding-bottom: 12vw;
	}
	.propMainCarousel {
		margin-left: 5vw;
		margin-right: 3vw;
	}
	.iconBoxDer {
		height: -webkit-fit-content;
		height: -moz-fit-content;
		height: fit-content;
	}
	.propertyInfoDesc1Der {
		font-size: 18px;
		line-height: 22px;
	}
	.iconsCont {
		margin: 0 auto;
	}
	.DerTitle {
		font-size: 22vw;
	}
	.DerTitleSmall {
		font-size: 70px;
	}
	.propImgsSec {
		width: 29vw;
		height: 39vw;
	}
	#propImgsSec2 {
	-webkit-transform: translateX(10.25vw);
	        transform: translateX(10.25vw);
	align-self: flex-start;
	/* margin-right: 9.3vw; */
}
}

.navbarContainer {
	position: fixed;
	top: 16px;
	left: 50%;
	-webkit-transform: translateX(-50%);
	        transform: translateX(-50%);
	z-index: 10000;
	width: 100vw;
	max-height: 70px;
}

.navBasket {
	padding: 8px 8px 8px 24px;
	width: 95vw;
	box-sizing: border-box;
	margin: auto;
	max-width: 1196px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: #f2f2f2;
	box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.04);
	border-radius: 64px;
}

.navBtn {
	background: #2a7560;
	border-radius: 56px;
	padding: 12px 40px;
	font-family: 'DM Sans';
	font-weight: 500;
	font-size: 24px;
	line-height: 31px;
	color: #ffffff;
	text-align: center;
	cursor: pointer;
}

@media screen and (max-width: 500px) {
	.nav-logo {
		max-width: 150px;
	}
	.navBtn {
		font-size: 14px;
		line-height: 18px;
		padding: 8px 20px;
	}
}

